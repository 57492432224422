<template>
  <div>
    <!-- Search -->
    <form @submit.prevent="fetchData()" class="w-72 relative mb-4 mr-1">
      <button class="absolute left-0 top-0 mt-2 pt-px ml-4 z-1">
        <img src="/images/icons/Search.svg" alt="Search Icon" class="w-5" />
      </button>

      <input
        type="text"
        class="bg-white w-full rounded-full pl-12 py-2 outline-none"
        v-model="searchValue"
        @keydown.esc="searchValue = null"
        placeholder="search"
      />
    </form>
    <!-- End Search -->
    <div class="px-4 mb-4 shadow rounded md:px-8 py-2 bg-white flex place-content-between">
      <div class="flex flex-col">
        <label for="filterType"> Type: </label>

        <select name="" id="filterType" class="styled rounded shadow" v-model="filterType">
          <option value="All">All</option>
          <option value="User">User</option>
          <option value="Product">Product</option>
          <option value="Variant">Variant</option>
          <option value="Order">Order</option>
          <option value="Vendor">Vendor</option>
          <option value="Coupon">Coupon</option>
          <option value="Invoice">Invoice</option>
          <option value="Purchase Order">Purchase Order</option>
        </select>
      </div>
      <!-- <div class="flex flex-col">
            <label for="startDeliveryDate">delivery after</label>
            <input
              type="date"
              id="startDeliveryDate"
              class="styled rounded shadow"
              v-model="startDeliveryDate"
              @change="clearOrders"
            />
          </div> -->
      <div class="flex flex-col">
        <label for="filterAction"> Action: </label>
        <select v-model="filterAction" id="filterAction" class="styled rounded shadow">
          <option value="All">All</option>
          <option value="Creation">Create</option>
          <option value="Deletion">Delete</option>
          <option value="Update">Update</option>
        </select>
      </div>
      <div class="flex flex-col">
        <label for="startDate">Start Date:</label>
        <input type="date" id="startDate" class="styled rounded shadow" v-model="startDate" />
      </div>
      <div class="flex flex-col">
        <label for="endDate">End Date:</label>
        <input type="date" id="endDate" class="styled rounded shadow" v-model="endDate" />
      </div>
    </div>
    <div class="px-4 mb-4 shadow rounded md:px-8 py-2 bg-white">
      <div class="grid grid-cols-5 font-bold" :key="sortBy">
        <div>
          Category
          <button @click="setSort('type')">
            <font-awesome-icon
              icon="chevron-down"
              class="transition-transform duration-200"
              :class="isSelected('type')"
            />
          </button>
        </div>
        <div>
          Action
          <button @click="setSort('category')">
            <font-awesome-icon
              icon="chevron-down"
              class="transition-transform duration-200"
              :class="isSelected('category')"
            />
          </button>
        </div>
        <div>
          Date
          <button @click="setSort('date')">
            <font-awesome-icon
              icon="chevron-down"
              class="transition-transform duration-200"
              :class="isSelected('date')"
            />
          </button>
        </div>
        <div>
          Email
          <button @click="setSort('email')">
            <font-awesome-icon
              icon="chevron-down"
              class="transition-transform duration-200"
              :class="isSelected('email')"
            />
          </button>
        </div>
        <div>
          Altered Object
          <button @click="setSort('name')">
            <font-awesome-icon
              icon="chevron-down"
              class="transition-transform duration-200"
              :class="isSelected('name')"
            />
          </button>
        </div>
      </div>
      <Entry
        v-for="(entry, index) in entries"
        :key="entry._id"
        :entry="entry"
        class="p-2"
        :class="index % 2 ? 'bg-gray-200' : null"
      />
    </div>
    <!-- pagination -->

    <Pagination
      pageType="Log"
      :admin="false"
      :totalAvailable="totalPages"
      :limit="limit"
      :currentPage="currentPage"
      :selectableLimits="selectableLimits"
      @setCurrentPage="setCurrentPage"
      @setLimit="setLimit"
    />
  </div>
</template>

<script>
import Entry from './Entry';
import Pagination from '@/components/shared/Pagination';

export default {
  data: function () {
    return {
      searchValue: null,
      startDate: null,
      endDate: null,
      filterType: 'All',
      filterAction: 'All',
      sortBy: '-date',
      limit: 25,
      currentPage: 1,
      selectableLimits: [50, 100, 200, 1000],
    };
  },
  props: {},
  computed: {
    totalPages() {
      return this.$store.getters.getLogQueryTotal;
    },
    entries() {
      return this.$store.getters.getLogs;
    },
  },
  watch: {
    startDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    endDate() {
      this.currentPage = 1;
      this.fetchData();
    },
    limit() {
      this.currentPage = 1;
      this.fetchData();
    },
    sortBy() {
      this.currentPage = 1;
      this.fetchData();
    },
    filterType() {
      this.currentPage = 1;
      this.fetchData();
    },
    filterAction() {
      this.currentPage = 1;
      this.fetchData();
    },
    currentPage() {
      this.fetchData();
    },
  },
  methods: {
    setSort(val) {
      if (this.sortBy == val) {
        this.sortBy = `-${val}`;
      } else {
        this.sortBy = val;
      }
    },
    isSelected(val) {
      if (this.sortBy === val) {
        return 'flip';
      }
    },
    buildQuery() {
      let query = '';

      query += `sort=${this.sortBy}&limit=${this.limit}&page=${this.currentPage}`;

      if (this.startDate) {
        const d = new Date(this.startDate);
        query +=
          '&date[gte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.endDate) {
        const d = new Date(this.endDate);
        query +=
          '&date[lte]=' +
          encodeURIComponent(d.toISOString().split('T')[0].slice(0, 10).replace(/-/g, '/'));
      }

      if (this.filterAction != 'All') {
        query += `&category=${this.filterAction}`;
      }

      if (this.filterType != 'All') {
        query += `&type=${this.filterType}`;
      }

      if (this.searchValue) {
        query += `&search=${this.searchValue}`;
      }
      return query;
    },
    async fetchData() {
      const query = this.buildQuery();
      this.$store.dispatch('getLogs', `?${query}`);
    },
    setCurrentPage(payload) {
      this.currentPage = payload;
      this.fetchData();
    },
    setLimit(payload) {
      this.currentPage = 1;
      this.limit = payload;
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  components: {
    Entry,
    Pagination,
  },
};
</script>
<style scoped>
.flip {
  transform: rotate(180deg);
}
</style>
